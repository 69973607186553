import React, { useState } from "react";
import PriceCard from "./PriceCard";
import SectionTitle from "./SectionTitle";
import mobilePriceAtom1 from "../assets/mobile-price-atom-1.svg";
import "../styles/PriceInfoSectionSliderStyles.scss";
import Slider from "react-slick";
import {priceData} from '../assets/priceCards'

const PriceInfoSectionSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const CustomPrevArrow = (props) => (
    <div {...props} className="custom-prev-arrow">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="34"
        viewBox="0 0 21 34"
        fill="none"
      >
        <path
          d="M3.33337 3.66666L16.6667 17L3.33337 30.3333"
          stroke="black"
          stroke-width="6"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );

  const CustomNextArrow = (props) => (
    <div {...props} className="custom-next-arrow">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="34"
        viewBox="0 0 21 34"
        fill="none"
      >
        <path
          d="M17.6667 3.66666L4.33333 17L17.6667 30.3333"
          stroke="black"
          stroke-width="6"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );

  const renderDots = () => {
    const dots = [];

    priceData.forEach((group, groupIndex) => {
      group.forEach((_, cardIndex) => {
        dots.push(
          <div
            key={groupIndex + cardIndex}
            className={`dot ${currentSlide === groupIndex ? "active" : ""}`}
          ></div>
        );
      });
    });

    return dots;
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    swipeToSlide: true,
    nextArrow: <CustomPrevArrow />,
    prevArrow: <CustomNextArrow />,
    afterChange: (index) => setCurrentSlide(index),
  };

  return (
    <div id="pricing" className="price-info">
      <div className="price-info-section-container">
        <img
          src={mobilePriceAtom1}
          alt="price-atom-1"
          className="mobile-price-atom-1"
        />
        <SectionTitle title="Цены:" />
        <div className="price-cards-container">
          <Slider {...settings}>
            {priceData.map((group, index) => (
              <div key={index} className="slider-container">
                {group.map((card, cardIndex) => (
                  <PriceCard
                    key={cardIndex}
                    className="style-1"
                    title={card.title}
                    cardName={card.cardName}
                    price={card.price}
                    description={card.description}
                    img={card.img}
                    buttonText={card.buttonText}
                  />
                ))}
              </div>
            ))}
          </Slider>
        </div>
        <div className="dots-container">{renderDots()}</div>
      </div>
    </div>
  );
};

export default PriceInfoSectionSlider;

import objectIspitanyiImg from "../assets/cardImages/object_ispitanyi.jpg";
import smkImg from "../assets/cardImages/smk.jpg";
import vlkImg from "../assets/cardImages/vlk.jpg";
import graduir from "../assets/cardImages/graduir.jpg";
import protokolIsp from "../assets/cardImages/protokolIsp.jpg";
import company from "../assets/cardImages/company.jpg";
import oborud from "../assets/cardImages/oborud.jpg";
import personal from "../assets/cardImages/personal.jpg";
import planing from "../assets/cardImages/planing.jpg";
import docs from "../assets/cardImages/docs.jpg";
import elJournal from "../assets/cardImages/elJournal.jpg";
import verefic from "../assets/cardImages/verefic.jpg";
import admin from "../assets/cardImages/admin.jpg";
import reactiv from "../assets/cardImages/reactiv.jpg";
import baseBlock from "../assets/cardImages/baseBlock.jpg";

export const priceData = [
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Объекты испытаний» lims it-lab",
      description:
        "Соблюдение прослеживания образцов и контроль процесса движения проб.",
      price: "от 115 000,00 руб.",
      buttonText: "Заказать",
      img: objectIspitanyiImg,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«СМК» lims it-lab",
      description:
        "Цифровой блок СМК предназначен для облегчения задач ведения процессов, связанных с системой менеджмента в лаборатории.",
      price: "от 40 250,00 руб.",
      buttonText: "Заказать",
      img: smkImg,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«ВЛК» lims it-lab",
      description:
        "Комплекс мероприятий по обеспечению контроля качества лабораторных исследований и измерений.",
      price: "от 172 500,00 руб.",
      buttonText: "Заказать",
      img: vlkImg,
    },
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Градуировочные зависимости» lims it-lab",
      description:
        "Позволяет автоматизировать процесс построения градуировочных зависимостей, провести отбраковку и проверку получаемых значений",
      price: "от 11 500,00 руб.",
      buttonText: "Заказать",
      img: graduir,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«Протоколы испытаний» lims it-lab",
      description:
        "Модуль предназначен для формирования итоговых отчетов по проведенным испытаниям.",
      price: "от 115 000,00 руб.",
      buttonText: "Заказать",
      img: protokolIsp,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«Компания» lims it-lab",
      description:
        "В данной подсистеме организован учет основных нормативно справочных объектов.",
      price: "от 11 500,00 руб.",
      buttonText: "Заказать",
      img: company,
    },
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Оборудование» lims it-lab",
      description:
        "Возможность вести список оборудования и документации по нему.",
      price: "от 17 250,00 руб.",
      buttonText: "Заказать",
      img: oborud,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«Персонал» lims it-lab",
      description: "Список сотрудников лаборатории.",
      price: "от 11 500,00 руб.",
      buttonText: "Заказать",
      img: personal,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«Планировщик» lims it-lab",
      description:
        "Система контроля и планирования трудового времени и работ сотрудников.",
      price: "от 11 500,00 руб.",
      buttonText: "Заказать",
      img: planing,
    },
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Документы» lims it-lab",
      description: "Процедуры управления всеми документами.",
      price: "от 11 500,00 руб.",
      buttonText: "Заказать",
      img: docs,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«Электронные журналы» lims it-lab",
      description:
        "Официальный документ, имеющий юридическую силу, в котором указываются условия проведения экспериментов и результаты измерений.",
      price: "от 11 500,00 руб.",
      buttonText: "Заказать",
      img: elJournal,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«Акт верификации» lims it-lab",
      description:
        "Определение и назначение сотрудников на конкретные испытания.",
      price: "от 11 500,00 руб.",
      buttonText: "Заказать",
      img: verefic,
    },
  ],
  [
    {
      title: "LIMS IT-LAB",
      cardName: "«Администрирование» lims it-lab",
      description:
        "Автоматический аудит действий пользователя в системе регистрация всех изменений, отчётности или журналов.",
      price: "от 11 500,00 руб.",
      buttonText: "Заказать",
      img: admin,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«Реактивы» lims it-lab",
      description: "Реактивы и расходные материалы лаборатории.",
      price: "от 17 250,00 руб.",
      buttonText: "Заказать",
      img: reactiv,
    },
    {
      title: "LIMS IT-LAB",
      cardName: "«Базовый блок»",
      description:
        "Включает в себя все необходимые комплектации для ведения отчетности и администрирования вашей лаборатории.",
      price: "от 287 500,00 руб.",
      buttonText: "Заказать",
      img: baseBlock,
    },
  ],
];
